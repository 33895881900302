import { Routes } from "@angular/router";

export const AppRoutes: Routes = [

  {
    path: '',
    redirectTo: 'hub',
    pathMatch: 'full',
  },
  {
    path: "hub",
    loadChildren: () => import('./hub/hub.module').then(m => m.HubModule)
  },
  {
    path: "chat",
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
  },
  {
    path: "my-page",
    loadChildren: () => import('./my-page/my-page.module').then(m => m.MyPageModule)
  },
  {
    path: "forum",
    loadChildren: () => import('./forum/forum.module').then(m => m.ForumModule)
  },
  {
    path: "templates",
    loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule)
  },
  {
    path: "tutor-panel",
    loadChildren: () => import('./tutor/tutor-panel.module').then(m => m.TutorPanelModule)
  },
  {
    path: "events",
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },
  {
    path: "notice",
    loadChildren: () => import('./notice/notice.module').then(m => m.NoticeModule)
  },
  {
    path: "contacts",
    loadChildren: () => import('./contacts/contacts.module').then(m => m.ContactsModule)
  },
  {
    path: "scheduling",
    loadChildren: () => import('./scheduling/scheduling.module').then(m => m.SchedulingModule)
  },
  {
    path: "payment",
    loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: "feed",
    loadChildren: () => import('./feed/feed.module').then(m => m.FeedModule)
  },
  {
    path: "new-flix",
    loadChildren: () => import('./new-flix/new-flix.module').then(m => m.NewFlixModule)
  },
  {
    path: "flix",
    loadChildren: () => import('./flix/flix.module').then(m => m.FlixModule)
  },
  {
    path: "swiper",
    loadChildren: () => import('./swiper/swiper.module').then(m => m.SwiperModule)
  },
  {
    path: "plans",
    loadChildren: () => import('./plans/plans.module').then(m => m.PlansModule)
  },
  {
    path: "new-login",
    loadChildren: () => import('./new-login/new-login.module').then(m => m.NewLoginModule)
  },
  {
    path: "new-portal",
    loadChildren: () => import('./new-portal/new-portal.module').then(m => m.NewPortalModule)
  },
  {
    path: "power-skills",
    loadChildren: () => import('./power-skills/power-skills.module').then(m => m.PowerSkillsModule)
  },
  {
    path: "new-bi",
    loadChildren: () => import('./new-bi/new-bi.module').then(m => m.NewBiModule)
  },
  {
    path: "library",
    loadChildren: () => import('./library/library.module').then(m => m.LibraryModule)
  },
  {
    path: "html",
    loadChildren: () => import('./html/html.module').then(m => m.HtmlModule)
  },
  {
    path: "rewards",
    loadChildren: () => import('./rewards/rewards.module').then(m => m.RewardsModule)
  },
  {
    path: "management",
    loadChildren: () => import('./management/management.module').then(m => m.ManagementModule)
  },
  {
    path: "performance",
    loadChildren: () => import('./performance/performance.module').then(m => m.PerformanceModule)
  },
  {
    path: "journey",
    loadChildren: () => import('./journey/journey.module').then(m => m.JourneyModule)
  },
  {
    path: "journeys-panel",
    loadChildren: () => import('./journeys-panel/journeys-panel.module').then(m => m.JourneysPanelModule)
  },
  {
    path: "cards",
    loadChildren: () => import('./cards/cards.module').then(m => m.CardsModule)
  },
  {
    path: "banner",
    loadChildren: () => import('./banner/banner.module').then(m => m.BannerModule)
  },
  {
    path: "view-all",
    loadChildren: () => import('./view-all/view-all.module').then(m => m.ViewAllModule)
  },
  {
    path: "edital",
    loadChildren: () => import('./edital/edital.module').then(m => m.EditalModule)
  },
  {
    path: "assessment",
    loadChildren: () => import('./assessment/assessment.module').then(m => m.AssessmentModule)
  },
  {
    path: "polls",
    loadChildren: () => import('./polls/polls.module').then(m => m.PollsModule)
  },
  {
    path: "new-viewer",
    loadChildren: () => import('./new-viewer/new-viewer.module').then(m => m.NewViewerModule)
  },
  {
    path: "new-admin-contents",
    loadChildren: () => import('./new-admin-contents/new-admin-contents.module').then(m => m.NewAdminContentsModule)
  },
  {
    path: "pdf-viewer",
    loadChildren: () => import('./pdf-viewer/asap-pdf-viewer.module').then(m => m.AsapPdfViewerModule)
  },
  {
    path: "channels-library",
    loadChildren: () => import('./new-channels-library/new-channels-library.module').then(m => m.NewChannelsLibraryModule)
  },
  {
    path: "cta-apps",
    loadChildren: () => import('./cta-apps/cta-apps.module').then(m => m.CtaAppsModule)
  },

  // Clientes
  {
    path: "impact-hub",
    loadChildren: () => import('./impact-hub/impact-hub.module').then(m => m.ImpactHubModule)
  },
  {
    path: "dara",
    loadChildren: () => import('./dara/dara.module').then(m => m.DaraModule)
  },
  {
    path: "revolucao-cidada",
    loadChildren: () => import('./revolucao-cidada/revolucao-cidada.module').then(m => m.RevolucaoCidadaModule)
  },
  {
    path: "conseguro",
    loadChildren: () => import('./conseguro/conseguro.module').then(m => m.ConseguroModule)
  },
  {
    path: "casarotto",
    loadChildren: () => import('./casarotto/casarotto.module').then(m => m.CasarottoModule)
  },
  {
    path: "coca-cola",
    loadChildren: () => import('./coca-cola/coca-cola.module').then(m => m.CocaColaModule)
  },

];
